.header {
    width: 100%;
    height: 15%;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 50;
    @media only screen and (max-width: 530px) {
        padding: 20px; }
    @media only screen and (max-width: 350px) {
        padding: 5px; }
    a {
        z-index: 2; }
    &__logo {
        display: flex;
        align-items: center;
        font-weight: bold;
        letter-spacing: 2px;
        z-index: 10;
        svg {
            width: 10%;
            fill: white;
            margin-right: 10px;
            min-width: 20px; }
        h6 {
            margin-left: 20px;
            @media only screen and (max-width: 450px) {
                margin-left: 5px; } } }
    &__line {
        border-left: 1px solid white;
        height: 50%;
        width: 1px;
        margin-left: 20px;
        min-height: 30px;
        @media only screen and (max-width: 450px) {
            margin-left: 5px;
            min-height: 20px; } }
    &__menu {
        display: flex;
        z-index: 10;
        @media only screen and (max-width: 450px) {
            padding: 0; }
        li {
            margin-left: 20px;
            @media only screen and (max-width: 450px) {
                margin-left: 5px; } } }
    &__button-register {
        padding: 10px 25px;
        background: $main-color;
        color: white;
        font-weight: bold;
        transition: all .5s;
        border-radius: 5px;
        &:hover {
            box-shadow: 0px 0px 15px 0px $main-color; } }
    &__button-login {
        padding: 10px 25px;
        background-color: $elements-color;
        color: white;
        font-weight: bold;
        transition: all .5s;
        border-radius: 5px;
        &:hover {
            background: $main-color; } }
    &__button-login, &__button-register {
        @media only screen and (max-width: 530px) {
            padding: 5px 10px; } } }

