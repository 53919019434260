.light {
    .portfolio {
        background-color: $light-panel-color;
        &__list tr {
            color: #3a1414;
            background-color: white;
            &:nth-child(1):hover {
                background-color: white; }
            &:hover {
                background-color: $light-inactive-color; } } } }

.portfolio {
    display: flex;
    width: 100%;
    .alertMessage {
        width: 30%;
        height: 20%;
        padding: 20px;
        min-height: 200px; }
    &__container {
        width: 95%;
        padding: 3%;
        padding-top: 2%;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: 600px) {
            width: 100%; } }
    &__list {
        width: 100%;
        font-size: 15px;
        border-spacing: 0 0px;
        white-space: nowrap;
        tr {
            background-color: $elements-color;
            height: 60px;
            transition: all .2s;
            @media only screen and (max-width: 500px) {
              &:nth-child(1) th:nth-child(3) {
                display: none; } }
            @media only screen and (max-width: 760px) {
              font-size: 12px; }
            @media only screen and (max-width: 400px) {
              font-size: 9px; }
            td:last-child svg {
                fill: white;
                width: 30px;
                padding: 5px;
                border-radius: 50px;
                transition: all .2s;
                cursor: pointer;
                &:hover {
                    fill: $main-color;
                    background-color: white; } }
            &:hover {
                background-color: $panel-color; } }
        tr:first-child th:first-child {
            border-top-left-radius: 15px; }
        tr:first-child th:last-child {
            border-top-right-radius: 15px; }
        tr:last-child td:first-child {
            border-bottom-left-radius: 15px; }
        tr:last-child td:last-child {
            border-bottom-right-radius: 15px; }
        td {
            text-align: center;
            padding: 10px;
            @media only screen and (max-width: 500px) {
              &:nth-child(3) {
                display: none; }
              padding: 5px; } }
        tr:first-child {
            &:hover {
                background-color: $elements-color; } } }
    tr td:nth-child(5) {
        font-weight: bold; }
    .errorMsg svg {
        width: 20%; } }
