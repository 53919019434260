.landing-page {
    width: 100%;
    height: 100%;
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 0;
        overflow: hidden;
        svg {
            height: 100%;
            fill: $main-color; } }
    &__heading {
        width: 100%;
        height: 85%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 30px;
        z-index: 10;
        h1 {
            font-style: italic;
            z-index: 10;
            max-width: 45%; }
        img {
            width: 60%;
            position: absolute;
            right: 30px;
            right: 0; } } }
