.page404 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    a {
        color: $main-color; }
    h1 {
        @include fluid-type(30px, 52px, 350px, 1600px);
        margin: 0; } }
