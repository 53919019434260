input {
  outline: none;
  border: none; }

/*--------------------------------------------- */

button {
  outline: none !important;
  border: none;
  background: transparent;
  &:hover {
    cursor: pointer; } }

iframe {
  border: none !important; }

/*//////////////////////////////////////////////////////////////////
 *[ Utility ] */

.txt1 {
  font-size: 13px;
  line-height: 1.5;
  color: white; }

.txt2 {
  font-size: 13px;
  line-height: 1.5;
  color: white; }

/*//////////////////////////////////////////////////////////////////
 *[ register ] */

.limiter {
  width: 100%;
  margin: 0 auto; }

.container-register {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: $panel-color;
  flex-direction: column; }

.wrap-register {
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.12);
  width: 960px;
  background: $elements-color;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 100px 100px;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 100%; }
  @media only screen and (max-width: 760px) {
    padding: 20px 20px; } }

.register-pic {
  width: 206px;
  img {
    max-width: 100%; } }

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px; }

.register-form-title {
  font-size: 24px;
  color: white;
  line-height: 1.2;
  text-align: center;
  font-weight: 800;
  width: 100%;
  display: block;
  padding-bottom: 54px; }

.wrap-input {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px; }

.input {
  font-size: 15px;
  line-height: 1.5;
  color: white;
  display: block;
  width: 100%;
  background: #515268;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 30px; }

.focus-input {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: $inactive-color; }

.input:focus + .focus-input {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards; }

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0; } }


@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0; } }

.container-register-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px; }

.register-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: $main-color;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  &:hover {
    background: white;
    color: $main-color; } }


@media (max-width: 992px) {
  .register-pic {
    width: 35%; }
  .register-form {
    width: 50%; } }

@media (max-width: 768px) {
  .register-pic {
    display: none; }
  .register-form {
    width: 100%; } }
