.light {
  .topbar {
    &__name {
      color: #3a1414; }
    &__searchbar input {
      color: $light-inactive-color; }
    &__results {
      background-color: $light-panel-color!important;
      border-color: $light-panel-color!important;
      border-top: 40px solid $light-panel-color !important;
      li {
        h4 {
          color: #3a1414; }
        &:hover {
          background-color: $light-elements-color!important; } } } }
  .mobileMenu {
    background-color: $light-panel-color; }
  .hamburger__inner {
    background-color: #3a1414!important;
    &:after,&:before {
      background-color: #3a1414!important; } } }
.hamburger {
  padding: 15px;
  cursor: pointer;
  display: none;
  overflow: hidden;
  background-color: transparent;
  z-index: 100;
  @media only screen and (max-width: 600px) {
    display: inline-block; } }

.hamburger__container {
  width: 36px;
  height: 24px;
  position: relative; }

.hamburger {
  &:hover .hamburger__inner {
    transform: translate(-51px, 50%);
    opacity: 0;
    &::before, &::after {
      transform: translate(102px, 0);
      opacity: 0; } }
  &.is-active .hamburger__inner {
    display: none; } }

.hamburger__inner {
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  top: 50%;
  transform: translate(5px, -50%);
  opacity: 1;
  &::before, &::after {
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    content: "";
    opacity: 1;
    transform: translate(-5px, 0); }
  &::before {
    top: -13px; }
  &::after {
    top: 13px; } }

.hamburger {
  &:hover .hamburger__hidden {
    opacity: 1;
    transform: translate(0, -50%);
    &::before, &::after {
      opacity: 1;
      transform: translate(0, 0); } }
  &.is-active .hamburger__hidden {
    opacity: 1;
    transform: rotate(45deg);
    &::before {
      transform: translate(0, 13px) rotate(90deg);
      transform-origin: center; }
    &::after {
      transform-origin: center;
      transform: translate(0, -13px) rotate(0); } } }

.hamburger__hidden {
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.4s;
  background-color: $main-color;
  top: 50%;
  transform: translate(51px, -50%);
  &::before, &::after {
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.4s;
    background-color: $main-color;
    content: "";
    transform: translate(102px, 0); }
  &::before {
    top: -13px; }
  &::after {
    top: 13px; } }

.topbar {
  width: 100%;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  &__name {
    font-weight: 800; }
  &__user {
    bottom: 20px;
    white-space: nowrap;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &__bottom {
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
  &__dev {
    padding: 10px;
    background: $dev-color;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px $dev-color;
    margin-right: 20px;
    display: flex;
    transition: all .4s;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    h4 {
      margin: 0; } }
  &__power {
    padding: 10px;
    background: linear-gradient(to right, rgb(124, 131, 255) 30%, rgb(124, 193, 255) 100%);
    border-radius: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    transition: all .4s;
    user-select: none;
    svg {
      fill: white;
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    h3 {
      color: $text-color;
      margin: 0; } }
  &__container {
    display: flex;
    align-items: center; } }
.topbar__searchbar {
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  margin-right: 80px;
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  position: relative;
  z-index: 10;
  height: 45px;
  justify-content: center;
  transition: .4s all;
  width: 40%;
  @media only screen and (max-width: 800px) {
    width: 60%; }
  @media only screen and (max-width: 600px) {
    display: none; }
  .topbar__results {
    transition: all .4s;
    position: absolute;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: $panel-color;
    z-index: -1;
    padding: 0;
    top: 0;
    border: 15px solid $panel-color;
    border-top: 40px solid $panel-color;
    border-radius: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: none;
    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;
      border-radius: 10px;
      transition: all .4s;

      a {
        color: white;
        width: 100%; }
      h4,h6 {
        margin: 5px 0; }
      h6 {
        color: $inactive-color;
        text-align: left; }
      &:hover {
        color: $main-color;
        background-color: $elements-color;
        cursor: pointer; } } }
  svg {
    height: 15px;
    fill: $searchbarText-color; }
  input {
    height: 30px;
    background-color: transparent;
    color: $searchbarText-color;
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    width: 100%; } }
.mobileMenu {
  width: 100%;
  position: absolute;
  background-color: $panel-color;
  height: 100%;
  z-index: 50;
  display: none;
  left: -100%;
  transition: all .3s;
  justify-content: center;
  align-items: center;
  bottom: 0;
  .leftbar {
    display: flex;
    background-color: transparent;
    height: 100%;
    svg {
      min-width: 50px; }
    &__logo {
      min-width: 80px; } } }
