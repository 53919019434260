// BRANDING
$main-color: #5eb5f8;
$text-color: #fefdff;

// DARK THEME

// LEFTBAR
$bar-color: #363644;
$hover-color: #ff8e9a;
$inactive-color: #676976;

// PANEL
$panel-color: #373a46;
$elements-color: #3c3c4d;
$searchbar-color: #3c3c4d;
$searchbarText-color: #8c8d96;
$dev-color: #f5427b;

// LIGHT THEME

$light-bar-color: #e2e1e8;
$light-panel-color: #edebf8;
$light-inactive-color: #999eaf;
$light-elements-color: #DDDBE7;
