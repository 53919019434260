@import '../abstracts/_mixins.sass';
h1 {
    @include fluid-type(33px, 47px, 350px, 1600px); }
h2 {
    @include fluid-type(20px, 32px, 350px, 1600px); }
h3 {
    @include fluid-type(15px, 18px, 350px, 1000px); }
h4 {
    @include fluid-type(10px, 13px, 350px, 1500px); }
h5 {
    @include fluid-type(8px, 11px, 350px, 1500px); }
h6 {
    @include fluid-type(7px, 10px, 350px, 1500px); }
p {
    @include fluid-type(9px, 12px, 350px, 1500px); }


h1,h2,h3 {
    color: $text-color; }
.light {
  h1,h2,h3 {
    color: #3a1414; } }
