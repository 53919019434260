.devPanel {
    width: 100%;
    height: 100%;
    padding: 50px;
    h1 {
        color: #8c8d96; }
    &__content {
        width: 100%;
        position: relative;
        table {
            width: 100%;
            z-index: 10;
            border-spacing: 15px;
            th {
                color: $dev-color;
                font-weight: 700;
                padding: 5px; }
            td {
                text-align: center;
                font-weight: 400; }
            input {
                text-align: center;
                padding: 10px 5px;
                background-color: $panel-color;
                color: white;
                border-radius: 10px;
                transition: all .5s;
                &:hover {
                    box-shadow: 0px 0px 5px 0px $dev-color; } } } } }
